// .carousel-card {
//     border-radius: 5px;
//     box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
//     transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
//     // width: 400px;
//     margin: 1px;
// }

// .carousel-card:hover {
//     box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
// }

.trending-image {
    height: 165px !important;
    width: 165px !important;
}

.custom-dot-list-style {
    bottom: -8px !important;
}

.services-card {
    border-radius: 100%;
    height: 150px;
    width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    border: double 4px transparent;
    background-image: linear-gradient(white, white), linear-gradient(to right, #b1a57e, gold);
    border-image-slice: 1;
    border-radius: 100px;
    background-clip: content-box, border-box;
    background-origin: border-box;
}

.home__shops-container {
    background: #ceffc9b2;
    border-radius: 5px;
}

.home__hr-line {
    height: 4px !important;
    color: #ffffff;
    background: #ffffff;
}

.view__all-btn {
    color: #333333 !important;
    font-weight: bold;
}

.banner-1 {
    background-color: #ef356299 !important;
    border-radius: 20px;
    color: #fff;
}

.banner-2 {
    background-color: #ffe91f52 !important;
    border-radius: 20px;
    color: #9b20cbcc;
}

.book-now-btn {
    background-color: #0c8134cc !important;
    color: #fff;
    border: 0;
    height: 60px;
}

.banner__image-1 {
    width: 500px;
}

.banner__image-2 {
    width: 250px;
    height: 250px;
    border-radius: 100%;
}

.banner__parent-group-1 {
    flex-direction: row;
}

.banner__parent-group-2 {
    flex-direction: row;
}

.services__icon {
    width: 90px;
    height: 90px;
}

@media screen and (max-width: 600px) {
    .banner__image-1 {
        width: 320px !important;
        margin-top: 10px;
    }

    .banner__parent-1 {
        flex-direction: column;
        text-align: center;
    }

    .banner__image-2 {
        width: 190px;
        height: 190px;
        border-radius: 100%;
        margin-top: 10px;
    }

    .banner__parent-group-1 {
        flex-direction: column;
        margin-bottom: 15px;
    }

    .banner__parent-group-2 {
        flex-direction: column;
    }

    .services-card {
        height: 100px;
        width: 100px;
    }

    .services__icon {
        width: 60px;
        height: 60px;
    }
}

.responsive-banner {
    display: inline-block;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    background-image: linear-gradient(to bottom right, #d45ba1, #a784e0);
    background-repeat: no-repeat;
    text-align: left;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.responsive-banner.second {
    background-image: linear-gradient(to bottom right, #f4c28e, #e67163);
}
.responsive-banner.third {
    background-image: linear-gradient(to bottom right, #589fe5, #74dac4);
}
.container-envelope {
    max-width: calc(100%);
    padding: 15px;
    color: #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-height: 300px;
    max-height: 450px;
}

a.more-link {
    background-color: rgba(255, 255, 255, 0.35);
    display: inline-block;
    padding: 12px 18px;
    color: #fff;
    text-decoration: none;
    font: 500 15px "Work Sans", Helvetica, sans-serif;
    line-height: 1.5;
    text-align: center;
    border: none !important;
    position: relative;
    border-radius: 30px;
    text-transform: uppercase;
    -webkit-transition: 0.1s all ease-in-out;
    -moz-transition: 0.1s all ease-in-out;
    -o-transition: 0.1s all ease-in-out;
    transition: 0.1s all ease-in-out;
}
a.more-link:hover {
    background-color: rgba(255, 255, 255, 0.5);
}
.cirle-a {
    bottom: -80px;
    left: -80px;
}
.cirle-b {
    top: -30px;
    right: 70%;
}
.cirle-c {
    top: -330px;
    right: -300px;
}
.cirle-d {
    top: 195px;
    right: 145px;
}

.cirle-a,
.cirle-b,
.cirle-c,
.cirle-d {
    fill: rgba(0, 0, 0, 0.1);
    position: absolute;
}

.women__icon__size {
    font-size: 50px;
}

.star__icon__size {
    font-size: 20px;
}

.heart__icon__size {
    font-size: 35px;
}

.location__icon__size {
    font-size: 25px;
}

.user__icon__size {
    font-size: 35px;
}

.user__star__icon__size {
    font-size: 20px;
}

.user__heart__icon__size {
    font-size: 20px;
}

.book__appointment-btn {
    background-color: #393434 !important;
}

.services__list-card {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border: 0 !important;
    height: 150px;
}

.shop__detail-carousel-img {
    height: 500px !important;
}

.shop__detail-service-name {
    font-size: 16px;
}

.shop__detail-service-discount {
    font-size: 16px;
}

.shop__detail-service-original {
    font-size: 12px;
    color: grey;
    padding-left: 5px;
}


.carousel-with-custom-dots {
    padding-bottom: 75px;
  }
  .custom-dot {
    border: none;
    margin: 5px;
    outline: none;
  }
  .custom-dot--active {
    transform: scale(1.3);
    outline: auto;
  }
  
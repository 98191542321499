.bg-blue {
    background: #2ca6decc !important;
}

.bg-violet {
    background: #9b20cbcc !important;
}

.bg-green {
    background: #f6c516cc !important;
}

.bg-olive {
    background: #ef3562cc !important;
}

.bg-party {
    background-color: #44bc58cc !important;
}

.bg-offers {
    background: #c94427cc !important;
}

.bg-party-green {
    background: #44bc58cc !important;
}

.cursor-pointer {
    cursor: pointer;
}

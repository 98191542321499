@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');
/* 


.f-32 {
  font-size: 4rem !important;
}

.f-24 {
  font-size: 3rem !important;
}

.f-16 {
  font-size: 2rem !important;
}

.f-12 {
  font-size: 12px !important;
}

.f-10 {
  font-size: 10px !important;
}

.f-8 {
  font-size: 1rem !important;
}

.font-weight-bold {
  font-weight: bold !important;
} */


/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.button-3d-container {
  perspective: 800px; /* Depth for the 3D effect */
  position: relative;
  display: inline-block;
  width: max-content;
}

.button-3d-front,
.button-3d-back {
  width: 100%;
  height: 100%;
  padding: 0.5rem 1rem; /* Matching padding for both faces */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.375rem; /* Tailwind rounded-lg */
  backface-visibility: hidden; /* Hides opposite side when flipped */
  transition: transform 0.6s ease; /* Smooth transition for rotation */
}

.button-3d-front {
  transform: rotateX(0deg); /* Default front face */
}

.button-3d-back {
  transform: rotateX(-90deg); /* Initially hidden, rotated to lie flat */
  position: absolute;
  top: 0;
  left: 0;
}

/* Rotate the button on hover */
.button-3d-container:hover .button-3d-front {
  transform: rotateX(90deg); /* Rotate front face upwards */
}

.button-3d-container:hover .button-3d-back {
  transform: rotateX(0deg); /* Rotate back face to become visible */
}


.carousel-card {
    border-radius: 5px;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    margin: 1px;
    background: #ffffff;
    border: 0 !important;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

// .carousel-card:hover {
//     box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
// }

.star__icon__size {
    font-size: 14px;
}

.shop__title-text {
    font-size: 18px;
    font-weight: bold;
}

.shop__reviews-text {
    font-size: 14px;
}

.shop__services-text {
    color: #7a7a7a;
}

.shop__location-icon {
    margin-left: 17px;
    color: #e70022;
}

.shop__address-details {
    font-size: 14px;
    width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.shop-image {
    height: 147px !important;
    width: auto;
}

.shop__get-appointment-btn {
    background-color: #393434 !important;
    font-size: 12px !important;
}

.shop__time {
    color: #1ea6f2;
}

.shop__time-text {
    font-size: 14px;
}

.shop__distance {
    margin-left: 5px;
}

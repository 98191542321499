.book__appointment-btn {
    background-color: #393434 !important;
}
.custom-datepicker .e-input-group.e-control-wrapper .e-input-group-icon {
    padding: 0.5rem; /* Adjust padding */
    background-color: #f3f4f6; /* Matching input background color */
    border-left: 1px solid #d1d5db; /* Add left border to separate icon */
    color: #6b7280; /* Icon color */
  }
  
  .custom-datepicker .e-input-group.e-control-wrapper {
    background-color: #f3f4f6; /* Matching input background color */
    border-radius: 0.375rem; /* Match the border radius */
    border: 1px solid #d1d5db; /* Light gray border */
  }
  
  .custom-datepicker .e-input {
    background-color: #f3f4f6 !important;
    color: #6b7280 !important;
    border: none !important; /* This ensures there's no border */
    border-radius: 0.375rem;
    padding: 0.5rem 1rem;
    box-shadow: none !important; /* Remove any focus shadow */
    height: auto;
}
  
  .custom-datepicker .e-input:focus {
    outline: none !important;
    border: none !important; /* Ensure no border is applied on focus */
    box-shadow: none !important; /* Remove any focus shadow */
  }
  